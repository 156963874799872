const __o365_sfc__ = {};
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "icon-wrapper"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [_createElementVNode("svg", {
    class: "ov-icon",
    "aria-hidden": "true",
    width: "32",
    height: "32",
    viewBox: "0.48 0.48 23.04 23.04",
    fill: "#fff"
  }, [_createElementVNode("path", {
    d: "M9.29 15.88L13.17 12 9.29 8.12a.996.996 0 111.41-1.41l4.59 4.59c.39.39.39 1.02 0 1.41L10.7 17.3a.996.996 0 01-1.41 0c-.38-.39-.39-1.03 0-1.42z"
  })], -1 /* HOISTED */)]));
}
    __o365_sfc__.name = 'RightArrow';
    __o365_sfc__.__file = 'RightArrow';
    try {
__o365_sfc__.render = render;
} catch (ex) {
    window['console'].warn(ex);
}
    __o365_sfc__.__scopeId = 'data-v-4b7c4eea-80bd-4896-ab6a-96957c2aa7a6';
    export default __o365_sfc__;
            let style = document.createElement('style');
            style.setAttribute('type', 'text/css');
            style.innerHTML = `
.icon-wrapper[data-v-4b7c4eea-80bd-4896-ab6a-96957c2aa7a6] {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 2px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

`;
            document.head.appendChild(style);
            